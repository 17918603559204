<script lang="ts" setup>
import { onMounted, ref } from "vue";
import {
  Role,
  InvitedUserInterface,
  ParticipantInterface,
} from "../../../interface/UserInterface";
import DeleteUserModal from "./DeleteUserModal.vue";
import EditUserModal from "./EditUserModal.vue";
import router from "../../../routes";
import UserInvitationModal from "./UserInvitationModal.vue";
import ProfileIconComponent from "../../../components/ProfileIconComponent.vue";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { ApiCallService } from "../../../services/apiCall.service";
import { StorageKeys, StorageService } from "../../../services/storage.service";
import { TokenInterface } from "../../../interface/TokenInterface";
import { ReportService } from "../../../services/reportService";
import { ActiveRoleStore } from "../../../stores/ActiveRoleStore";
import { CurrentInvitationStore } from "../../../stores/CurrentInvtationStore";
import { ToastService } from "../../../services/toastService";
import { LoadingService } from "../../../services/loadingService";
import { useRoute } from "vue-router";

const props = defineProps({
  user: {
    type: Object as () => InvitedUserInterface,
    required: true,
  },
  showDetail: {
    type: Boolean,
    default: true,
  },
  forReports: {
    type: Boolean,
    default: false,
  },
  companyId: {
    type: Number,
  },
});

const route = useRoute();

const emit = defineEmits(["invite", "delete", "edit"]);

const showDeleteModal = ref(false);
const toBeDeleted = ref();
const currentModalUser = ref();
const showEditModal = ref(false);
const showInviteUserModal = ref(false);
const pariticpantData = ref<ParticipantInterface>();
const token = ref("");
const pdfUrl = ref("");
const activeRoleStore = ActiveRoleStore();
const invitationStore = CurrentInvitationStore();

const userService = appContainer.resolve(UserService);
const apiCallService = appContainer.resolve(ApiCallService);
const storageService = appContainer.resolve(StorageService);
const reportService = appContainer.resolve(ReportService);
const toastService = appContainer.resolve(ToastService);
const loadingService = appContainer.resolve(LoadingService);

onMounted(async () => {
  if (props.forReports && props.user.invitation_type === Role.Participants) {
    await getPdfUrl();
    await getParticipantData();
  }
});

async function getParticipantData() {
  userService
    .feedbackDurationlleft(props.user.id)
    .then((result) => (pariticpantData.value = result));
}

function emitInvite(id: number) {
  closeInviteModal();
  emit("invite", id);
}

function openDeleteModal(id: number) {
  toBeDeleted.value = id;
  showDeleteModal.value = true;
}

function openEditModal(user: InvitedUserInterface) {
  currentModalUser.value = user;
  showEditModal.value = true;
}

function emitEdit() {
  closeEditModal();
  emit("edit");
}

function closeEditModal() {
  showEditModal.value = false;
}

function showDetails(userID: number) {
  if (props.forReports && props.user.invitation_type === Role.Admin) {
    router.push(`/manage-reports/${userID}/participant`);
  }
  if (!props.showDetail) {
    return;
  }
  router.push(`/manage-users/user-details/${userID}`);
}

function emitDelete(id: number) {
  showDeleteModal.value = false;
  emit("delete", id);
}

function closeInviteModal() {
  showInviteUserModal.value = false;
}

async function getPdfUrl() {
  token.value = (
    (await storageService.get(StorageKeys.TOKEN)) as TokenInterface
  ).access_token;
  pdfUrl.value = `${apiCallService.baseUrl()}/invitations/${props.user.id}/report/pdf/?token=${token.value}`;
}
function getDate() {
  const sentOn = new Date(props.user.sent_on);
  sentOn.setDate(sentOn.getDate() + Number(props.user.meta.duration_days));
  const day = sentOn.getDate();
  const month = sentOn.toLocaleString("default", { month: "long" });
  const year = sentOn.getFullYear();

  return `${day} ${month} ${year}`;
}

async function sendPdf() {
  const loader = await loadingService.show();
  const loginInvitationId =
    activeRoleStore.activeRole?.role_name === "super_admin"
      ? null
      : invitationStore.currentInvitation!.id;
  await reportService
    .sendReportPdf(props.user.id, loginInvitationId)
    .then(() => toastService.success("PDF Sent Successfully"))
    .catch(() => toastService.error("Unable to Send PDF"))
    .finally(() => loader.hide());
}

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};
</script>

<template>
  <div
    class="user-card d-flex border-bottom p-md-3 p-2 align-items-center"
    :class="{ 'cursor-pointer': !props.companyId }"
    @click.stop="showDetails(props.user.id)"
  >
    <ProfileIconComponent :user="props.user" />
    <div class="d-flex flex-column justify-content-center">
      <span class="fw-semibold name text-capitalize"
        >{{ props.user.invited_user.first_name }}
        {{ props.user.invited_user.last_name }}</span
      >
      <span class="email" :class="{ 'text-primary': user.invitation_sent }">{{
        props.user.invited_user.email
      }}</span>
      <div
        class="role-wrapper"
        v-if="
          props.forReports && props.user.invitation_type === Role.Participants
        "
      >
        <span>
          Assessment completed on:
          {{ getDate() }}</span
        >
      </div>

      <div
        class="role-wrapper d-flex align-items-center mt-1"
        v-if="!props.forReports"
      >
        <span class="text-capitalize">{{ props.user.invitation_type }}</span>
        <span
          v-if="
            props.user.invitation_type === Role.Participants ||
            props.user.invitation_type === Role.Admin
          "
          class="pipe"
          >|</span
        >
        <span v-if="props.user.invitation_type === Role.Participants"
          >Total Raters: {{ user.item_count }}
        </span>
        <span v-if="props.user.invitation_type === Role.Admin"
          >Total Participants: {{ user.item_count }}
        </span>

        <span
          class="pipe"
          v-if="
            props.user.invitation_type === Role.Participants ||
            props.user.invitation_type === Role.Raters
          "
        >
          |
        </span>
        <div class="d-flex justify-content-center">
          <span v-if="props.user.invitation_type === Role.Participants">
            Admin: {{ props.user.invited_for_user?.email }}
          </span>
          <span v-if="props.user.invitation_type === Role.Raters"
            >Participant: {{ props.user.invited_for_user?.email }}
          </span>
        </div>
        <span class="pipe"> | </span>
        <span> Created on: {{ formatDate(user.created_at) }} </span>
      </div>
    </div>
    <div
      v-if="!props.forReports"
      class="d-flex align-items-center action-items ms-auto"
    >
      <button v-if="showDetail" title="View More" class="btn border-0 p-1">
        <i class="bi bi-arrow-up-right-square"></i>
      </button>
      <button
        title="Send Invitation Email"
        class="btn border-0 p-1"
        @click.stop="showInviteUserModal = true"
      >
        <i class="bi bi-envelope"></i>
      </button>
      <button
        v-if="!user.invitation_sent"
        title="Edit User"
        class="btn border-0 p-1"
        @click.stop="openEditModal(props.user)"
      >
        <i class="bi bi-pencil-square"></i>
      </button>
      <button
        v-if="!user.invitation_sent"
        title="Delete Invitation"
        class="btn border-0 p-1"
        @click.stop="openDeleteModal(props.user.id)"
      >
        <i class="bi bi-trash3"></i>
      </button>
    </div>
    <div
      class="d-flex align-items-center action-items ms-auto"
      v-if="
        props.forReports && props.user.invitation_type === Role.Participants
      "
    >
      <template v-if="props.user.meta.comments_deleted">
        <a
          target="_blank"
          :href="pdfUrl"
          class="btn"
          v-if="!pariticpantData?.days_left"
        >
          <i class="bi bi-download"></i>
        </a>
        <button class="btn border-0" @click="sendPdf">
          <i class="bi bi-envelope"></i>
        </button>
      </template>
      <button
        v-else
        class="btn"
        @click="
          Number(route.params.id)
            ? router.push(`reports/${props.user.id}/edit`)
            : router.push(`/manage-reports/${props.user.id}/edit`)
        "
      >
        <i class="bi bi-pencil-square"></i>
      </button>
    </div>
  </div>
  <DeleteUserModal
    v-if="showDeleteModal"
    :invitation="props.user"
    @close="showDeleteModal = false"
    @delete="emitDelete"
  />

  <EditUserModal
    v-if="showEditModal"
    :user="currentModalUser"
    @close="closeEditModal"
    @edit="emitEdit"
  />
  <UserInvitationModal
    v-if="showInviteUserModal"
    :invitation="props.user"
    @invite="emitInvite"
    @close="closeInviteModal"
  />
</template>

<style lang="scss" scoped>
@import "../../../theme/variables";

.user-card {
  transition: all ease 300ms;
  gap: 12px;
}

.email {
  font-size: 12px;
  color: #999;
}

.role-wrapper {
  gap: 10px;
  span {
    font-size: 12px;
  }
}

.action-items {
  gap: 10px;

  .btn {
    font-size: 18px;

    transition: ease 300ms;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 575px) {
  .role-wrapper {
    flex-direction: column;
    align-items: start !important;
    gap: 0;
  }
  .pipe {
    display: none;
  }
  .action-items {
    justify-content: center;
    flex-direction: column;
    gap: 8;
    .btn {
      font-size: 16px;
      padding: 2px !important;
    }
  }
}
</style>
