<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { ThemeService } from "../services/themeService";
import {
  ThemeInterface,
  LinkFor,
  ThemeCategory,
} from "../interface/ThemeInterface";
import { appContainer } from "../container";
import { LoadingService } from "../services/loadingService";
import { ToastService } from "../services/toastService";
import UploadLogoComponent from "../components/UploadLogoComponent.vue";
import { StorageKeys, StorageService } from "../services/storage.service";

const themeService = appContainer.resolve(ThemeService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const storageService = appContainer.resolve(StorageService);

const brandLogo = ref<File>();
const linkPrivacy = ref("");
const linkTerms = ref("");
const linkType = LinkFor;
const leadLogo = ref();
const developLogo = ref();
const careLogo = ref();
const eqlogo = ref();
const isLoading = ref(true);
const logoSrc = ref();
const token = ref("");

const formData = ref<ThemeInterface>({
  primary_color: "#ffffff",
  secondary_color: "#ffffff",
  tertiary_color: "#ffffff",
  logo_file: "",
  category_colors: {
    lead: {
      color: "#ffffff",
      logo: null,
    },
    develop: {
      color: "#ffffff",
      logo: null,
    },
    care: {
      color: "#ffffff",
      logo: null,
    },
    eqSelf: {
      color: "#ffffff",
      logo: null,
    },
    eqOthers: {
      color: "#ffffff",
      logo: null,
    },
  },
  footer_string: "",
});

const linkErrors = ref({
  privacyPolicy: "",
  terms: "",
});

onBeforeMount(async () => {
  await initThemeData();
  if (formData.value.logo_file) {
    token.value = (
      (await storageService.get(StorageKeys.TOKEN)) as any
    ).access_token;
    logoSrc.value = `${formData.value.logo_file}?token=${token.value}`;
  }
});

async function initThemeData() {
  const loader = await loadingService.show();
  return await themeService
    .getThemeData()
    .then((result) => {
      if (result) {
        formData.value = result;
      }
    })
    .finally(() => {
      isLoading.value = false;
      loader.hide();
    });
}
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;
  if (files && files[0]) {
    brandLogo.value = files[0];
    logoSrc.value = URL.createObjectURL(brandLogo.value);
  }
};

async function handleSubmitTheme() {
  const isValid = validateForm();
  if (!isValid) return;

  const loader = await loadingService.show();
  try {
    await themeService.saveTheme(formData.value);
    if (brandLogo.value) await themeService.brandLogo(brandLogo.value);
    if (leadLogo.value)
      await themeService.categoryLogo(ThemeCategory.Lead, leadLogo.value);

    if (developLogo.value)
      await themeService.categoryLogo(ThemeCategory.Develop, developLogo.value);
    if (careLogo.value)
      await themeService.categoryLogo(ThemeCategory.Care, careLogo.value);
    if (eqlogo.value)
      await themeService.categoryLogo(ThemeCategory.EQSelf, eqlogo.value);

    toastService.success("Theme colors added successfully");
    initThemeData();
  } catch (error) {
    toastService.error("Failed to save theme colors");
  } finally {
    loader.hide();
  }
}
function setUploadedLogo(logo: File, category: string) {
  switch (category) {
    case "lead":
      leadLogo.value = logo;
      break;
    case "develop":
      developLogo.value = logo;
      break;
    case "care":
      careLogo.value = logo;
      break;
    case "eq":
      eqlogo.value = logo;
      break;
    default:
      toastService.error("Somthing Went Wrong");
  }
}

async function handleSubmitLink(type: LinkFor, link: string) {
  const isValid = validateLink(type, link);
  if (!isValid) return;

  const loader = await loadingService.show();
  try {
    await themeService.saveLinks(link, type);
    toastService.success("Link added successfully");
  } catch (error) {
    toastService.error("Failed to add link");
  } finally {
    loader.hide();
  }
}

function validateLink(type: string, link: string) {
  let isValid = true;
  linkErrors.value = { privacyPolicy: "", terms: "" };

  if (!link) {
    if (type === linkType.Privacy) {
      linkErrors.value.privacyPolicy = "Privacy Policy link should be URL.";
    } else if (type === linkType.Terms) {
      linkErrors.value.terms = "Terms and Conditions link should be URL.";
    }
    isValid = false;
  }

  return isValid;
}

// Error Handling
const errors = ref({
  primary_color: "",
  secondary_color: "",
  tertiary_color: "",
  category_colors: {
    lead: "",
    develop: "",
    care: "",
    eqSelf: "",
    eqOthers: "",
  },
  footer_string: "",
});

function validateForm() {
  let isValid = true;

  // Reset errors
  errors.value = {
    primary_color: "",
    secondary_color: "",
    tertiary_color: "",
    category_colors: {
      lead: "",
      develop: "",
      care: "",
      eqSelf: "",
      eqOthers: "",
    },
    footer_string: "",
  };

  if (formData.value.primary_color === "#ffffff") {
    errors.value.primary_color = "Primary color is required.";
    isValid = false;
  }
  if (formData.value.secondary_color === "#ffffff") {
    errors.value.secondary_color = "Secondary color is required.";
    isValid = false;
  }
  if (formData.value.tertiary_color === "#ffffff") {
    errors.value.tertiary_color = "Tertiary color is required.";
    isValid = false;
  }
  if (formData.value.category_colors.lead.color === "#ffffff") {
    errors.value.category_colors.lead = "Lead color is required.";
    isValid = false;
  }
  if (formData.value.category_colors.develop.color === "#ffffff") {
    errors.value.category_colors.develop = "Develop color is required.";
    isValid = false;
  }
  if (formData.value.category_colors.care.color === "#ffffff") {
    errors.value.category_colors.care = "Care color is required.";
    isValid = false;
  }
  if (formData.value.category_colors.eqSelf.color === "#ffffff") {
    errors.value.category_colors.eqSelf = "EQ Self color is required.";
    isValid = false;
  }
  if (formData.value.category_colors.eqOthers.color === "#ffffff") {
    errors.value.category_colors.eqOthers = "EQ Others color is required.";
    isValid = false;
  }
  if (!formData.value.footer_string) {
    errors.value.footer_string = "Footer text is required.";
    isValid = false;
  }

  return isValid;
}
</script>

<template>
  <template v-if="!isLoading">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link border-0 border-bottom m-0 fw-medium active me-4"
          id="nav-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-home"
          type="button"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
        >
          Customization For Reports
        </button>
        <button
          class="nav-link border-0 m-0 border-bottom fw-medium"
          id="nav-profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-profile"
          type="button"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
        >
          External Links
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active py-4"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
        tabindex="0"
      >
        <form @submit.prevent="handleSubmitTheme">
          <div class="theme-wrap flex-column">
            <div class="wrap-theme-colors container-fluid">
              <div class="row">
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="primary_color">Heading Color</label>
                    <input
                      type="color"
                      id="primary_color"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.primary_color"
                    />
                  </div>
                  <div
                    v-if="errors.primary_color"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.primary_color }}
                  </div>
                </div>
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="secondary_color">Paragraph Color</label>
                    <input
                      type="color"
                      id="secondary_color"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.secondary_color"
                    />
                  </div>
                  <div
                    v-if="errors.secondary_color"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.secondary_color }}
                  </div>
                </div>
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="tertiary_color">Background Color</label>
                    <input
                      type="color"
                      id="tertiary_color"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.tertiary_color"
                    />
                  </div>
                  <div
                    v-if="errors.tertiary_color"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.tertiary_color }}
                  </div>
                </div>
              </div>
              <p class="fw-bold mb-2 mt-3">Lead</p>
              <div class="row">
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="lead">Color</label>
                    <input
                      type="color"
                      id="lead"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.category_colors.lead.color"
                    />
                  </div>
                  <div
                    v-if="errors.category_colors.lead"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.category_colors.lead }}
                  </div>
                </div>
                <UploadLogoComponent
                  category="lead"
                  @logoUpdated="setUploadedLogo"
                  :imgSrc="formData.category_colors.lead.logo"
                />
              </div>
              <p class="fw-bold mb-2 mt-3">Develop</p>
              <div class="row">
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="develop">Color</label>
                    <input
                      type="color"
                      id="develop"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.category_colors.develop.color"
                    />
                  </div>
                  <div
                    v-if="errors.category_colors.develop"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.category_colors.develop }}
                  </div>
                </div>
                <UploadLogoComponent
                  category="develop"
                  :imgSrc="formData.category_colors.develop.logo"
                  @logoUpdated="setUploadedLogo"
                />
              </div>
              <p class="fw-bold mb-2 mt-3">Care</p>
              <div class="row">
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="care">Color</label>
                    <input
                      type="color"
                      id="care"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.category_colors.care.color"
                    />
                  </div>
                  <div
                    v-if="errors.category_colors.care"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.category_colors.care }}
                  </div>
                </div>
                <UploadLogoComponent
                  :imgSrc="formData.category_colors.care.logo"
                  category="care"
                  @logoUpdated="setUploadedLogo"
                />
              </div>
              <p class="fw-bold mb-2 mt-3">EQ Self / EQ Others</p>
              <div class="row">
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="eqSelf">EQ Self Color</label>
                    <input
                      type="color"
                      id="eqSelf"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.category_colors.eqSelf.color"
                    />
                  </div>
                  <div
                    v-if="errors.category_colors.eqOthers"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.category_colors.eqOthers }}
                  </div>
                </div>
                <div class="col-3 pe-5">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label for="eqOthers">EQ Others Color</label>
                    <input
                      type="color"
                      id="eqOthers"
                      class="form-control theme-color overflow-hidden"
                      v-model="formData.category_colors.eqOthers.color"
                    />
                  </div>
                  <div
                    v-if="errors.category_colors.eqOthers"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.category_colors.eqOthers }}
                  </div>
                </div>
                <UploadLogoComponent
                  category="eq"
                  :imgSrc="formData.category_colors.eqSelf.logo"
                  @logoUpdated="setUploadedLogo"
                />
              </div>
              <div class="row">
                <div class="col position-relative mb-3">
                  <label for="logo" class="fw-bold mb-2 mt-3"
                    >Footer Info</label
                  >
                  <input
                    type="text"
                    id="footerInfo"
                    class="form-control"
                    v-model="formData.footer_string"
                  />
                  <div
                    v-if="errors.footer_string"
                    class="error mt-2 text-danger"
                  >
                    {{ errors.footer_string }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 position-relative mb-3">
                  <label for="logo" class="fw-bold mb-2 mt-3"
                    >Upload Brand Logo</label
                  >
                  <input
                    type="file"
                    id="logo"
                    class="form-control"
                    @change="handleFileUpload"
                  />
                </div>
                <div v-if="logoSrc" class="col-6 pe-5 mt-auto">
                  <div
                    class="position-relative d-flex align-items-center justify-content-between h-100"
                  >
                    <label>Preview</label>
                    <img :src="logoSrc" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-wrapper mt-5">
              <button class="btn btn-primary text-white">
                Freeze for Reports
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        class="tab-pane fade py-4"
        id="nav-profile"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
        tabindex="0"
      >
        <form
          @submit.prevent="handleSubmitLink(linkType.Privacy, linkPrivacy)"
          class="row flex-column"
        >
          <div class="position-relative col-12 mb-3">
            <div class="d-flex align-items-end">
              <div class="me-2 w-100">
                <label for="privacyPolicy">Privacy Policy</label>
                <input
                  type="text"
                  id="privacyPolicy"
                  class="form-control"
                  v-model="linkPrivacy"
                />
              </div>
              <button type="submit" class="btn btn-primary text-white">
                Add Link
              </button>
            </div>
            <div v-if="linkErrors.privacyPolicy" class="error mt-2 text-danger">
              {{ linkErrors.privacyPolicy }}
            </div>
          </div>
        </form>
        <form
          @submit.prevent="handleSubmitLink(linkType.Terms, linkTerms)"
          class="row flex-column"
        >
          <div class="position-relative col-12 mb-3">
            <div class="d-flex align-items-end">
              <div class="me-2 w-100">
                <label for="terms">Terms and Conditions</label>
                <input
                  type="text"
                  id="terms"
                  class="form-control"
                  v-model="linkTerms"
                />
              </div>
              <button type="submit" class="btn btn-primary text-white">
                Add Link
              </button>
            </div>
            <div v-if="linkErrors.terms" class="error mt-2 text-danger">
              {{ linkErrors.terms }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
</template>

<style scoped lang="scss">
label,
button {
  white-space: nowrap;
}
nav {
  button {
    color: #212529;
    border-bottom-color: #fff !important;
    border-bottom-width: 2px !important;
    &.active {
      color: #4a84dd !important;
      border-bottom-color: #4a84dd !important;
    }
  }
}
.theme-color {
  height: 40px;
  padding: 0px;
  width: 40px;
  margin-left: 16px;
}

.custom-width {
  width: 450px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  padding: 0;
}
img {
  object-fit: contain;
  height: 60px;
  width: 60px;
}
.col-3 {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.row {
  align-items: baseline;
}
</style>
